@use '../abstracts' as *

// Panel Style


.Panel-Block
  @include flex-row
  width: 100%

.panel-input
  @include flex-row
  margin: 16px 0
  height: 30px
  width: $component-width


.panel-input input 
  width: 188px
  padding-left: 10px
  border-width: 1px
  border-radius: 5px 0px 0px 5px


.btn-primary 
  margin-right: 0px
  height: 30px
  width: 50px
  background: lightskyblue
  color: white
  border: 0
  border-radius: 0px 3px 3px 0px
  --webkit-appearance: none


input[type="number"]  
  padding: 0px 0px 0px 10px
  border-width: 1px
  border-radius: 5px 0px 0px 5px



.panel 
  @include flex-column
  width: $component-width
  height: auto
  padding: 20px 0px 20px 0px
  
  