@use '../App.sass' as app
@use 'Panel.sass'


// DropPanel Style

select, option 
  max-width: 200px

.Wrapper 
  height: 30px
  width: auto
  display: flex
  justify-content: center
  flex-direction: row
  margin-top: 16px
  margin-bottom: 16px

.rndButton
  margin-right: 0px
  height: 30px
  width: 50px
  background: lightskyblue
  color: white
  border: 0
  border-radius: 0px 3px 3px 0px
  -webkit-appearance: none

.country-root 
  position: relative
  overflow: hidden
  max-width: 200px

.country-control 
  font-family: 'Roboto'
  position: relative
  overflow: hidden
  background-color: white
  border: 1px solid #ccc
  border-radius: 5px 0px 0px 5px
  box-sizing: border-box
  color: #333
  cursor: default
  outline: none
  
  padding: 4px 0px 4px 10px
  transition: all 200ms ease
  border-radius: 5px 0px 0px 5px
  max-width: 200px
  min-width: 200px
  text-align: left

.country:hover 
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06)

.country-control option .is-open
  max-width: 200px !important

.is-open .country-arrow 
  border-color: transparent transparent #999
  border-width: 0 5px 5px
  max-width: 200px

.country-option 
  box-sizing: border-box
  color: rgba(51, 51, 51, 0.8)
  cursor: pointer
  display: block
  padding: 8px 10px
  // width: 200px
  overflow: hidden
  width: 200px !important
  max-width: 200px !important
  white-space: nowrap
  text-overflow: ellipsis

.country-menu
  max-width: 200px

.country, option 
  overflow: hidden
  // width: 200px !important
  text-overflow: ellipsis


.country-option:last-child 
  // app.$border-bottom-right-radius: 2px
  // app.$border-bottom-left-radius: 2px


.country-option:hover 
  background-color: #f2f9fc
  color: #333


.country-option.is-selected 
  background-color: #f2f9fc
  color: #333


.country-noresults 
  box-sizing: border-box
  color: #ccc
  cursor: default
  display: block
  padding: 8px 10px
