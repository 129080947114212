@use '../abstracts' as *


.panel-card 
  @include flex-column
  align-items: center
  font-size: .75rem
  width: $component-width
  height: auto
  border-radius: 10px
  border: 1px solid
  border-color: $primary-background-color
  display: block
  margin: 0px 10px
  padding: 16px 0px 16px 0px
  
  
  
.card-line
  height: 25px
  margin: .1rem 5px
  // text-align: left
  display: flex
  flex-flow: row nowrap
  justify-content: flex-start


.card-line-country
  margin: .1rem 5px
  // text-align: left
  // display: flex
  // flex-flow: row nowrap
  // justify-content: flex-start

.card-block 
  height: 25px
  /*width: 100%*/
  margin: 5px 10px
  text-align: left
  display: flex
  flex-direction: row
  /* justify-content: space-around */


.card-block-Label 
  
  /* display: flex */
  
  margin: 0px 5px 0px 30px
  width: 80px
  position: left
  display: block


.card-uNameWrapper 
  height: 25px
  display: flex
  flex-direction: row
  justify-content: space-evenly
  width: auto
  // margin: 0px 30px 5px 40px
  /* padding: 0px 5px */


.card-uAgeWrapper 
  height: 25px
  display: flex
  flex-direction: row
  justify-content: space-around
  width: auto
  margin: 0px 30px 5px 40px
  /* padding: 0px 5px */

.card-uGenderWrapper 
  height: 25px
  display: flex
  flex-direction: row
  justify-content: space-around
  width: auto
  margin: 0px 30px 5px 40px
  /* padding: 0px 5px */


.card-uItem 
  margin: 0px 0px 0px .5em
  color: $toggle-dark-icon-color
  text-align: right
  /* display: block */
  overflow: hidden

.card-uItem-Last 
  margin-left: 30px


.card-uItem-Center 
  margin: 0px 0px
  color: gray
  
.card-countryItem
  margin: 0 .8em 0 .8em
  color: $toggle-dark-icon-color
  text-align: left
  
  /* display: block */
  overflow: hidden


.card-ItemWrapper  
  display: flex
  flex-direction: row
  justify-content: center
  margin: 0px 30px 5px 40px


.card-pLabel 
  // width: 60px
  /* display: flex */
  height: 100%
  text-align: center
  margin: 0px 0px 5px .8em


.card-pName 
  // width: 100%
  
  // display: flex
  // flex-direction: column
  // justify-content: center
  text-align: center

.card-pGen 
  
  display: flex
  flex-direction: row
  justify-content: space-around