$component-width: 250px
$navbar-main-height: 40px
$hr-main-height: 12px
$sideSocials-width: 70px
$sideContacts-width: 70px
$toggle-width: 50px
$toggle-height: 25px

$transition-distance: 20px

/* 
 *  Setting Dark Blue as Base Theme
 */

$light-blue-color: hsl(218, 100%, 63%)

$primary-background-color: #f2f4f7
$secondary-background-color: $light-blue-color
$primary-header-bg-color: gray

$primary-color: black

$primary-button-color: hsl(203, 92%, 75%)
$secondary-button-color: hsl(0, 100%, 50%)

$neon-blue: #04d9ff
$text-shadow-slategray: #708090
$text-shadow-darkslategray: darkslategray
$logo-color: $primary-color
$pilar-color: $primary-color



$toggle-light-icon-color: white
$toggle-light-icon-bg-color: gray
$toggle-dark-icon-color: #cdd1dd 
$toggle-dark-icon-bg-color: #66bb6a
$toggle-light-icon: "\f185"
$toggle-dark-icon: "\f186"



$full-slider-transition: 0.4s
$half-slider-transition: 0.2s
$toggle-icon-before-pos-left: 7px
$toggle-icon-pos-bottom: 4px
