@mixin flex-row
  display: flex
  flex-flow: row nowrap
  justify-content: center

@mixin flex-column
  display: flex
  flex-flow: column nowrap
  justify-content: center
  align-items: center

@mixin button-height
  height: 40px

@mixin flex-row-spaced
  display: flex
  flex-flow: row nowrap
  justify-content: space-evenly
