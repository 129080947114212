@use '../abstracts' as *
@use '../App.sass' as app

// Buttons Style


.edit 
  @include flex-row-spaced
  width: 100%



.space 
  width: 30px
  height: 30px


button 
  color: white
  border: 0
  border-radius: 5px 5px 5px 5px
  -webkit-appearance: none


.generic 
  @include  button-height
  width: 75px
  background-color: lightskyblue


.clear 
  @include button-height
  width: 50px
  background: red
