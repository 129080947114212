$dark-original-color:hsl(0, 0%, 14%)
$dark-blue-color: hsl(235, 66%, 20%)
$dark-blue-alt-color:hsl(235, 58%, 17%) 
$dark-blue2-color:hsl(235, 45%, 15%) 
$dark-blue3-color:hsl(235, 25%, 15%)
$dark-theme-primary-bg-color: $dark-blue3-color
$dark-theme-secondary-bg-color: $dark-blue-color
$dark-theme-main-color: hsl(225, 19%, 84%)

$dark-primary-button-color:hsl(203, 75%, 60%)
$dark-secondary-button-color:hsl(0, 85%, 30%)
