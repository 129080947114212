@use '../abstracts' as *
@import "https://use.fontawesome.com/releases/v5.13.0/css/all.css"

.header-bar
    height: auto
    width: 100%

.topbar-wrapper
    height: 2rem
    background-color: $toggle-dark-icon-color
    border-radius: 50px 50px 0px 0px

.topbar
    width: $component-width
    display: flex
    flex-flow: row nowrap
    margin: 0 auto
    height: 2rem

.socials-wrapper
    @include flex-row
    justify-content: flex-start
    width: 50%

.socials
    margin-left: 1em
    display: flex
    align-items: center
    color: $primary-color

.fa-github
    color: $primary-color
    font-size: 1.4rem
    text-decoration: none

.a
    color: $primary-color
    text-decoration: none
    /* font-family: "Train One"; */
    font-size: 1.4rem
    transition: transform 0.1s ease

.a:link
    direction: none

.socials:hover
    transform: scale(1.2)

//Slider css

.theme-toggle-wrapper
    @include flex-row
    justify-content: flex-end
    width: 50%

.theme-toggle
    @include flex-row
    justify-content: flex-end
    position: relative
    align-self: center
    width: $toggle-width
    height: $toggle-height
    pointer-events: none
    user-select: none
    margin: 0px 0px

.theme-toggle input
    opacity: 0
    width: 100%
    height: 100%
    pointer-events: all

.slider
    position: absolute
    cursor: pointer
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: $toggle-light-icon-bg-color
    transition: $full-slider-transition
    pointer-events: none

.slider::before
    position: absolute
    font-family: "Font Awesome 5 Free"
    content: $toggle-light-icon
    color: $toggle-light-icon-color
    font-weight: 900
    font-size: calc(0.33 * #{$toggle-width})
    width: auto
    left: $toggle-icon-before-pos-left
    bottom: $toggle-icon-pos-bottom
    transition: $half-slider-transition
    pointer-events: none

.slider::after
    position: absolute
    transition: $half-slider-transition
    pointer-events: none
    right: 2px
    font-size: .75em
    top: 4px
    color: $toggle-light-icon-color

input:checked + .slider
    background-color: $toggle-dark-icon-bg-color

input:checked + .slider:before
    transform: translateX($transition-distance)
    font-family: "Font Awesome 5 Free"
    content: $toggle-dark-icon
    color: $toggle-dark-icon-color
    font-weight: 900
    font-size: calc(0.33 * #{$toggle-width})
    width: auto
    bottom: $toggle-icon-pos-bottom

.slider.round
    border-radius: 34px

.slider.round:before
    border-radius: 50%
